<template>
    <v-container fluid v-if="Number(params.curso_id)">
        <Formulario :getParams="this.params"></Formulario>
    </v-container>
</template>

<script>
const Formulario = () => import("./components/Formulario.vue");
import Auth from "@/services/auth";

export default {
    name: 'CursosEditar',
    data() {
        return {
            permisos: [],
            loading: true,
            params: {
                curso_id: null,
                permisos: []
            }
        }
    },
    components: {
        Formulario
    },
    methods: {
        verificarToken() {

            if (typeof this.$route.query.token !== "undefined") {
                try {
                    let token = this.$route.query.token;
                    this.params.curso_id = window.atob(token);
                } catch (e) {
                    console.log(e);
                    this.$toast.error("El parametro para Editar no es valido.");
                    this.regresar();
                }

            } else {
                this.$toast.error("Debe enviar un parametro para Editar.");
                this.regresar();
            }
        },
        regresar() {
            this.$router.go(-1);
        }
    },
    created() {
        Auth.checkPermisos("/cursos/aprobados").then((res) => {
            this.permisos = res;

            if (!this.permisos[3]) {
                this.regresar();
            }

            this.params.permisos = this.permisos;

            this.verificarToken();

        });
    }
}
</script>

<style>

</style>
